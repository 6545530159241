// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

global.toastr = require("toastr")
require('toastr/toastr.scss')
require("src/cocoon")
require("src/google_places_api")
require("flatpickr");
require("bootstrap-select");
window.jQuery = $;
window.$ = $;
window.Cookies = require('js-cookie/src/js.cookie')
require('jquery-ui-dist/jquery-ui');

import 'bootstrap'
import 'bootstrap-select'
import 'lodash';
import 'raty-js'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"

document.addEventListener('stimulus-reflex:success', event => {
    var reflex_load_arr = ['PlanReflex#plan_template', 'PlanReflex#variable_association_1','PlanReflex#variable_association_2', 'PlanReflex#variable_association_3', 'PlanReflex#variable_association_4', 'PlanReflex#variable_association_5', 'PlanReflex#variable_association_6']
    if(reflex_load_arr.indexOf(event.detail.reflex) > -1){
        $('body .ui-loader-background').css('display', 'none');
    }
})