global.googleMapAPI = {

    /**
     * Google Places Autocomplete
     */
    initAutocomplete: function (element) {
        console.log(element)
        var self = $(this);
        // Create the autocomplete object, restricting the search to geographical
        // location types.
        self.autocomplete = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */
            (element), {
                types: ['geocode', 'establishment']
            });

        // When the user selects an address from the dropdown, populate the address
        // fields in the form.
        self.autocomplete.addListener('place_changed', fillInAddress);

        function fillInAddress() {
            // Get the place details from the autocomplete object.
            var place = self.autocomplete.getPlace();
            // Get each component of the address from the place details
            // and fill the corresponding field on the form.
            var val = null;
            val = place.formatted_address
            $(element).val(val)
        }
    }
};