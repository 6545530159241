import ApplicationController from './application_controller'
import { throttle } from 'lodash-es'
/* This is the custom StimulusReflex controller for PlanReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /* Reflex specific lifecycle methods.
   * Use methods similar to this example to handle lifecycle concerns for a specific Reflex method.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Example:
   *
   *   <a href="#" data-reflex="PlanReflex#example">Example</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "PlanReflex#example"
   *
   *   error - error message from the server
   */

  // beforeUpdate(element, reflex) {
  //  element.innerText = 'Updating...'
  // }

  // updateSuccess(element, reflex) {
  //   element.innerText = 'Updated Successfully.'
  // }

    beforeReflex(element, reflex) {
        if(reflex == 'PlanReflex#variable_association_1') {
            if($('#plan_activity_type').val() == 'contouring'){
                $('.plan_tps_id').fadeOut(100, function () {
                    var prev_element = $(this).prev()
                    $(this).remove();
                    $(prev_element).css('position', 'unset')
                    $(prev_element).css('z-index', '0')
                });
                $('.plan_technique_id').fadeOut(100, function () {
                    var prev_element = $(this).prev()
                    $(this).remove();
                    $(prev_element).css('position', 'unset')
                    $(prev_element).css('z-index', '0')
                });
            }else {
                $('.plan_technique_id').fadeOut(100, function () {
                    var prev_element = $(this).prev()
                    $(this).remove();
                    $(prev_element).css('position', 'unset')
                    $(prev_element).css('z-index', '0')
                });
                $('.plan_machine_id').fadeOut(100, function () {
                    var prev_element = $(this).prev()
                    $(this).remove();
                    $(prev_element).css('position', 'unset')
                    $(prev_element).css('z-index', '0')
                });
                $('.plan_tps_id').fadeOut(100, function () {
                    var prev_element = $(this).prev()
                    $(this).remove();
                    $(prev_element).css('position', 'unset')
                    $(prev_element).css('z-index', '0')
                });
                $('.plan_dose_algorithm_id').fadeOut(100, function () {
                    var prev_element = $(this).prev()
                    $(this).remove();
                    $(prev_element).css('position', 'unset')
                    $(prev_element).css('z-index', '0')
                });
                $('.plan_grid_size_id').fadeOut(100, function () {
                    var prev_element = $(this).prev()
                    $(this).remove();
                    $(prev_element).css('position', 'unset')
                    $(prev_element).css('z-index', '0')
                });
                $('.plan_modality_id').fadeOut(100, function () {
                    var prev_element = $(this).prev()
                    $(this).remove();
                    $(prev_element).css('position', 'unset')
                    $(prev_element).css('z-index', '0')
                });
            }
        }else if(reflex == 'PlanReflex#variable_association_2'){
            $('.plan_machine_id').fadeOut(100, function(){
                var prev_element = $(this).prev()
                $(this).remove();
                $(prev_element).css('position', 'unset')
                $(prev_element).css('z-index', '0')
            });
            $('.plan_tps_id').fadeOut(100, function(){
                var prev_element = $(this).prev()
                $(this).remove();
                $(prev_element).css('position', 'unset')
                $(prev_element).css('z-index', '0')
            });
            $('.plan_dose_algorithm_id').fadeOut(100, function(){
                var prev_element = $(this).prev()
                $(this).remove();
                $(prev_element).css('position', 'unset')
                $(prev_element).css('z-index', '0')
            });
            $('.plan_grid_size_id').fadeOut(100, function () {
                var prev_element = $(this).prev()
                $(this).remove();
                $(prev_element).css('position', 'unset')
                $(prev_element).css('z-index', '0')
            });
            $('.plan_modality_id').fadeOut(100, function(){
                var prev_element = $(this).prev()
                $(this).remove();
                $(prev_element).css('position', 'unset')
                $(prev_element).css('z-index', '0')
            });
        }else if(reflex == 'PlanReflex#variable_association_3'){
            $('.plan_tps_id').fadeOut(100, function(){
                var prev_element = $(this).prev()
                $(this).remove();
                $(prev_element).css('position', 'unset')
                $(prev_element).css('z-index', '0')
            });
            $('.plan_dose_algorithm_id').fadeOut(100, function(){
                var prev_element = $(this).prev()
                $(this).remove();
                $(prev_element).css('position', 'unset')
                $(prev_element).css('z-index', '0')
            });
            $('.plan_grid_size_id').fadeOut(100, function () {
                var prev_element = $(this).prev()
                $(this).remove();
                $(prev_element).css('position', 'unset')
                $(prev_element).css('z-index', '0')
            });
            $('.plan_machine_id').fadeOut(100, function(){
                var prev_element = $(this).prev()
                $(this).remove();
                $(prev_element).css('position', 'unset')
                $(prev_element).css('z-index', '0')
            });
        }else if(reflex == 'PlanReflex#variable_association_4'){
            $('.plan_dose_algorithm_id').fadeOut(100, function(){
                var prev_element = $(this).prev()
                $(this).remove();
                $(prev_element).css('position', 'unset')
                $(prev_element).css('z-index', '0')
            });
            $('.plan_grid_size_id').fadeOut(100, function () {
                var prev_element = $(this).prev()
                $(this).remove();
                $(prev_element).css('position', 'unset')
                $(prev_element).css('z-index', '0')
            });
            $('.plan_tps_id').fadeOut(100, function(){
                var prev_element = $(this).prev()
                $(this).remove();
                $(prev_element).css('position', 'unset')
                $(prev_element).css('z-index', '0')
            });
        }else if(reflex == 'PlanReflex#variable_association_5'){
            if(!($('#plan_activity_type').val() === 'contouring')) {
                $('.plan_dose_algorithm_id').fadeOut(100, function () {
                    var prev_element = $(this).prev()
                    $(this).remove();
                    $(prev_element).css('position', 'unset')
                    $(prev_element).css('z-index', '0')
                });
                $('.plan_grid_size_id').fadeOut(100, function () {
                    var prev_element = $(this).prev()
                    $(this).remove();
                    $(prev_element).css('position', 'unset')
                    $(prev_element).css('z-index', '0')
                });

            }
        }else if(reflex == 'PlanReflex#variable_association_6'){
            $('.plan_grid_size_id').fadeOut(100, function () {
                var prev_element = $(this).prev()
                $(this).remove();
                $(prev_element).css('position', 'unset')
                $(prev_element).css('z-index', '0')
            });
        }

        var reflex_load_arr = ['PlanReflex#plan_template', 'PlanReflex#variable_association_1','PlanReflex#variable_association_2', 'PlanReflex#variable_association_3', 'PlanReflex#variable_association_4', 'PlanReflex#variable_association_5', 'PlanReflex#variable_association_6']
        if(reflex_load_arr.indexOf(reflex) > -1){
            $('body .ui-loader-background').css('display', 'unset');
        }
    }

    afterReflex (element, reflex) {
        if(reflex == 'PlanReflex#variable_association_1'){
            if($('#plan_activity_type').val() == 'contouring'){
                $('#plan_tps_id').selectpicker()
                $('#plan_technique_id').selectpicker()
                $('#plan_technique_id').attr('disabled',true)
                $('#plan_technique_id').selectpicker('refresh')
            }else {
                $('#plan_technique_id').selectpicker()
                $('#plan_machine_id').selectpicker()
                $('#plan_tps_id').selectpicker()
                $('#plan_dose_algorithm_id').selectpicker()
                $('#plan_grid_size_id').selectpicker()
                $('#plan_modality_id').selectpicker()
                protonAlgorithmSelection();
            }
        }else if(reflex == 'PlanReflex#variable_association_2'){
            $('#plan_machine_id').selectpicker()
            $('#plan_tps_id').selectpicker()
            $('#plan_dose_algorithm_id').selectpicker()
            $('#plan_grid_size_id').selectpicker()
            $('#plan_modality_id').selectpicker()
            protonAlgorithmSelection();
        }else if(reflex == 'PlanReflex#variable_association_3'){
            $('#plan_tps_id').selectpicker()
            $('#plan_dose_algorithm_id').selectpicker()
            $('#plan_grid_size_id').selectpicker()
            $('#plan_machine_id').selectpicker()
        }else if(reflex == 'PlanReflex#variable_association_4'){
            $('#plan_dose_algorithm_id').selectpicker()
            $('#plan_grid_size_id').selectpicker()
            $('#plan_tps_id').selectpicker()
        }else if(reflex == 'PlanReflex#variable_association_5'){
            if(!($('#plan_activity_type').val() === 'contouring')) {
                $('#plan_dose_algorithm_id').selectpicker()
                $('#plan_grid_size_id').selectpicker()
            }
        }else if(reflex == 'PlanReflex#variable_association_6'){
            $('#plan_grid_size_id').selectpicker()
        }
    }

    reflexError (element, reflex, error) {
        if(reflex == 'PlanReflex#plan_value_submit' || reflex == 'PlanReflex#plan_value_clear' || reflex == 'PlanReflex#note_submit'){
            window.location = '/dashboard'
        }
    }

    // updateError(element, reflex, error) {
  //   console.error('updateError', error);
  //   element.innerText = 'Update Failed!'
  // }
}

function protonAlgorithmSelection() {
    var technique = $('#plan_technique_id').val();

    if((technique === null || technique === '') || !$('#plan_technique_id option:selected').text().toLowerCase().includes('proton')){
        disableInputs()
    }else{
        enableInputs()
    }

    $('#plan_technique_id').on('change', function () {
        if(($('#plan_technique_id').val() === null || $('#plan_technique_id').val() === '') || !$('#plan_technique_id option:selected').text().toLowerCase().includes('proton')){
            disableInputs()
        }else {
            enableInputs()
        }
    })

    function disableInputs() {
        $('#plan_spot_spacing_id').prop('disabled', true)
        $('#plan_spot_spacing_id').val(null).trigger("change");
        $('#plan_spot_spacing_id').selectpicker('refresh');
        $('#plan_worst_case_scenario_coverage_id').prop('disabled', true)
        $('#plan_worst_case_scenario_coverage_id').val(null).trigger("change");
        $('#plan_worst_case_scenario_coverage_id').selectpicker('refresh');
        $('#plan_robustness_evaluation_criterium_id').prop('disabled', true)
        $('#plan_robustness_evaluation_criterium_id').val(null).trigger("change");
        $('#plan_robustness_evaluation_criterium_id').selectpicker('refresh');
        $('#plan_evaluation_conjunction').prop('disabled', true)
        $('#plan_evaluation_conjunction').prop("checked", false);
    }

    function enableInputs() {
        $('#plan_spot_spacing_id').prop('disabled', false)
        $('#plan_spot_spacing_id').selectpicker('refresh');
        $('#plan_worst_case_scenario_coverage_id').prop('disabled', false)
        $('#plan_worst_case_scenario_coverage_id').selectpicker('refresh');
        $('#plan_robustness_evaluation_criterium_id').prop('disabled', false)
        $('#plan_robustness_evaluation_criterium_id').selectpicker('refresh');
        $('#plan_evaluation_conjunction').prop('disabled', false)
    }
}
